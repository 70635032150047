:root {
  --transition: 0.2s;
  --borderRadius: 4px;
  --borderWidth: 6px;
  --background: #FFFFFF;
  --accent: #EE2677;
  --bright: #00E8FC;
  --purple: #C1BFF8;
  --yellow: #ffe734;
  --white: #FFFFFF;
  --blue: #0046DC;
  --text: #000000;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

img {
  max-width: 100%;
  height: auto;
}

@font-face {
  font-family: "Gabarito";
  src: url("/fonts/Gabarito-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 1000;
}
body, html {
  margin: 0 auto;
  font-family: "Gabarito";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  font-size: 1rem;
  width: 100%;
  color: var(--text);
  background-color: var(--background);
  overflow-x: hidden;
  overscroll-behavior-y: none;
  box-sizing: border-box;
  padding: 25px;
}
@media (max-width: 768px) {
  body, html {
    padding: 10px;
  }
}

body {
  box-sizing: border-box;
  position: relative;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
}

a {
  color: var(--blue);
  font-weight: 600;
}

input, input:before, input:after {
  user-select: initial;
  box-shadow: none;
}

img {
  pointer-events: none;
  user-select: none;
}

.no-scrollbars,
.no-scrollbars * {
  scrollbar-width: none;
}
.no-scrollbars::-webkit-scrollbar,
.no-scrollbars *::-webkit-scrollbar {
  width: 0px;
  display: none;
}
.no-scrollbars::-webkit-scrollbar-thumb,
.no-scrollbars *::-webkit-scrollbar-thumb {
  display: none;
  width: 0px;
}

.padded {
  box-sizing: border-box;
  padding-left: 8vw;
  padding-right: 8vw;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0rem;
  margin-bottom: 0;
  line-height: 1.5rem;
  position: relative;
}

h1 {
  font-size: 3.4rem;
  line-height: 3.8rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  h1 {
    font-size: 2.2rem;
    line-height: 2.75rem;
  }
}

h2 {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

h3 {
  font-size: 2.214rem;
  line-height: 2.75rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  h3 {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
}

h4 {
  font-size: 1.407rem;
  line-height: 1.5rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  h4 {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }
}

h5 {
  font-size: 1.01333333rem;
  font-weight: 700;
}

h6 {
  font-size: 0.89rem;
  font-weight: 700;
  color: var(--text);
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-size: 0.55em;
  display: block;
  font-weight: 500;
}

p,
.paragraph {
  font-size: 1rem;
  line-height: 1.75rem;
  max-width: 700px;
  padding: 12px 0;
  color: inherit;
  font-weight: 400;
}
@media (max-width: 768px) {
  p,
  .paragraph {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
}
p b,
.paragraph b {
  font-weight: 700;
}
p.big,
.paragraph.big {
  font-size: 2rem;
  line-height: 2.75rem;
}

a {
  text-decoration: none;
}

#logo img {
  width: 100px;
  height: auto;
  display: inline-block;
}
@media (max-width: 768px) {
  #logo img {
    width: 80px;
  }
}
#logo h1 {
  display: inline-block;
  font-size: 66px;
  font-weight: 800;
  letter-spacing: -0.5px;
}
@media (max-width: 768px) {
  #logo h1 {
    font-size: 46px;
  }
}

#homeText {
  display: block;
  max-width: 1200px;
  font-size: 110px;
  line-height: 100px;
  margin-top: 150px;
  font-weight: 800;
  max-width: 1000px;
}
@media (max-width: 768px) {
  #homeText {
    font-size: 74px;
    line-height: 64px;
    margin-top: 75px;
  }
}
#homeText small {
  font-size: 20px;
  display: inline-block;
  max-width: 400px;
  line-height: 24px;
  font-weight: 400;
  vertical-align: top;
  padding-top: 60px;
}
@media (max-width: 768px) {
  #homeText small {
    padding-top: 30px;
  }
}

#homeNo {
  text-wrap: nowrap;
  margin: 100px 0 0 0;
  max-width: 100%;
  overflow: auto;
  opacity: 0.24;
}

#homeP1 {
  margin-top: 100px;
}

#projectHeader {
  margin-top: 100px;
  font-size: 70px;
}

#projectLinks {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 10px 50px;
  margin-bottom: 100px;
}
#projectLinks a {
  font-weight: 800;
  font-size: 42px;
}
